export const infoCol = [
  {
    label: '用户名',
    prop: 'nickName'
  },
  {
    label: '手机(登录账号)',
    prop: 'mobile'
  },
  {
    label: '邮箱',
    prop: 'email'
  },
  {
    label: '分销商类型',
    prop: 'isProductPublic'
  },
  {
    label: '会员等级',
    prop: 'memberLevel'
  },
  {
    label: '采购额',
    prop: 'purchaseAmount'
  },
  {
    label: '余额',
    prop: 'balance'
  }
]

export const ISPRODUCTPUBLIC = ['分销商', '公共产品上新']
