<template>
  <div class="destroy-user-info app-container" v-loading="loading">
    <div class="accountInfoPart">
      <p class="info-title-bar">注销账号信息</p>
      <div class="info-content">
        <template v-for="{ label, prop } in infoCol">
          <p v-if="prop === 'isProductPublic'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ ISPRODUCTPUBLIC[userData[prop]] }}</span>
          </p>
          <p v-else-if="prop === 'levelName'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{
              userData.paymentAccountSimple && userData.paymentAccountSimple.memberLevel.levelName
            }}</span>
          </p>
          <p v-else-if="prop === 'salesAmount'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{
              userData.paymentAccountSimple && userData.paymentAccountSimple[prop]
            }}</span>
          </p>
          <p v-else-if="prop === 'balance'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ userData[prop] }}</span>
            <color-text-btn
              v-if="checkPermission('externaladmin:user:destroy:accountInfo:updateBalance')"
              style="padding-left: 20px"
              @click="hanldeBalance"
              >余额增减</color-text-btn
            >
            <color-text-btn v-if="checkPermission('externaladmin:user:destroy:accountInfo:history')" @click="showHistory"
              >历史记录</color-text-btn
            >
          </p>
          <p v-else-if="prop === 'isDisabled'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ userData[prop] ? '解冻' : '冻结' }}</span>
          </p>

          <p v-else :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ userData[prop] }}</span>
          </p>
        </template>
      </div>
    </div>
    <div class="childAccountInfoPart">
      <p class="info-title-bar">注销子账号</p>
      <div class="info-content">
        <CommonTable
          :cols="[
            {
              prop: 'nickName',
              label: '账号名称',
              width: '180px'
            },
            {
              prop: 'accountName',
              label: '账号',
              width: '180px'
            }
          ]"
          width="361px"
          height="450px"
          :selection="false"
          :infoData="userData.childrenList"
        >
        </CommonTable>
      </div>
    </div>
    <div class="accountInfoPart">
      <p class="info-title-bar">注销记录</p>
      <div class="info-content">
        <p>
          <span class="info-content-label">【注销原因】</span>
          <span class="info-content-font">
            <span v-for="(v, i) in cancellationReason" :key="i"> {{ v }}</span>
          </span>
        </p>
        <p v-if="userData.accountName !== userData.updateById">
          <span class="info-content-label">【操作人员】</span>
          <span class="info-content-font">{{ userData.updateById }}</span>
        </p>
        <p>
          <span class="info-content-label">【注销时间】</span>
          <span class="info-content-font">{{ parseTime(userData.cancellationDate) }}</span>
        </p>
      </div>
    </div>
    <div class="btnPart" v-loading="loading">
      <el-button size="small" @click="goBack">返回</el-button>
    </div>
    <balance-dialog
      ref="balanceDialog"
      :sup_this="sup_this"
      :balanceData="balanceData"
      :form.sync="form"
      @refreshPage="refreshPage"
      @updateForm="updateForm"
    />
  </div>
</template>

<script>
import { read } from '@/api/adminUser'
import { cancellationUserInfo } from '@/api/user'
import { infoCol, ISPRODUCTPUBLIC } from './const'
import BalanceDialog from '../module/balanceDialog.vue'
import { parseTime } from '@/utils'
import { REASON } from './option'
export default {
  components: { BalanceDialog },
  props: {},
  data() {
    return {
      sup_this: this,
      REASON,
      userData: {},
      infoCol,
      ISPRODUCTPUBLIC,
      data: [],
      loading: false,
      form: {
        currentBalance: null,
        balanceChange: null,
        modifiedBalance: null,
        remark: ''
      },
      balanceData: {}
    }
  },
  computed: {
    cancellationReason() {
      const arr = []
      this.userData?.cancellationReason?.map((v) => {
        this.REASON.map((v2) => {
          if (v2.reason == v) {
            if (v === 'otherReason') {
              arr.push(`${this.userData.otherReason}`)
            } else {
              arr.push(v2.label)
            }
          }
        })
      })
      return arr
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    parseTime,
    hanldeBalance() {
      this.balanceData = this.userData
      this.form.currentBalance =$GET(this,'userData.balance',0)
      this.form.balanceChange = 0
      this.form.modifiedBalance = $GET(this,'userData.balance',0)
      this.$refs.balanceDialog.balanceVisible = true
    },
    updateForm(data) {
      const { balanceChange, modifiedBalance } = data
      this.form.balanceChange = balanceChange
      this.form.modifiedBalance = modifiedBalance
    },
    refreshPage() {
      this.getUserData()
    },
    async getUserData() {
      try {
        this.loading = true
        const { detail } = await cancellationUserInfo({ id: this.$route.query.id })
        this.userData = detail
        this.loading = false
      } catch {
        this.loading = false
      }finally{
        this.loading = false
      }
    },
    goBack() {
      this.$router.back()
    },
    showHistory(data) {
      this.$router.push({
        path: '/user/destroy/history',
        query: {
          id: this.userData.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.destroy-user-info {
  .accountInfoPart {
    margin-bottom: 40px;
    .info-title-bar {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 3px solid $color-primary;
    }
    .info-content {
      font-size: 14px;
      p {
        margin: 0;
        margin-bottom: 15px;
        .info-content-label {
          margin-right: 10px;
          font-weight: 600;
        }
        .info-content-font {
          color: #333;
        }
      }
    }
  }
  .childAccountInfoPart {
    margin-bottom: 40px;
    .info-title-bar {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 3px solid $color-primary;
    }
    .info-content {
      font-size: 14px;
      .item {
        span {
          display: inline-block;
          width: 180px;
          padding: 10px;
        }
        span:nth-child(odd) {
          border-left: 1px solid $border-color;
          border-top: 1px solid $border-color;
        }
        span:nth-child(even) {
          border-left: 1px solid $border-color;
          border-right: 1px solid $border-color;
          border-top: 1px solid $border-color;
        }
      }
      .item:last-child span {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  .processing-record {
    .record-title-bar {
      margin: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 3px solid $color-primary;
      .record-info {
        font-size: 14px;
        margin-left: 40px;
        .record-info-frozen {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
