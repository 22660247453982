export const REASON = [
  {
    label: '上传违规图片，侵犯第三方合法权益',
    reason: 'uploadViolation'
  },
  {
    label: '账户名称存在违法或不良信息；盗用他人账户、发布违禁信息、欺诈、售假、扰乱市场秩序、采取不正当牟利等行为',
    reason: 'badInfomation'
  },
  {
    label: '非法买卖交易，买卖，违反中华人民共和国相关法律法规和有关政策',
    reason: 'illegalTrading'
  },
  {
    label: '多次违反平台规则规定且情节严重',
    reason: 'rulesViolation'
  },
  {
    label: '依据国家部门/政府机构要求，采取中止/终止提供服务操作',
    reason: 'nationalRequirements'
  },
  {
    label: '用户违规已被暂停正丁云商服务后，再一次或间接以他人名义注册为正丁云商平台用户',
    reason: 'userViolation'
  },
  {
    label: '其他原因',
    reason: 'otherReason'
  }
]